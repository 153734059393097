// font
@import url('https://fonts.googleapis.com/css2?family=Inter&display=swap');

// bootstrap
@import '~bootstrap/dist/css/bootstrap.css';

// toastr
@import "~react-toastify/dist/ReactToastify.css";

// datepicker
$datepicker__background-color: #f0f0f0 !default;
$datepicker__border-color: #aeaeae !default;
$datepicker__highlighted-color: #3dcc4a !default;
$datepicker__muted-color: #ccc !default;
$datepicker__selected-color: #45adea !default;
$datepicker__text-color: #000 !default;
$datepicker__header-color: #000 !default;
$datepicker__navigation-disabled-color: lighten(
                $datepicker__muted-color,
                10%
) !default;

$datepicker__border-radius: 0.3rem !default;
$datepicker__day-margin: 0.166rem !default;
$datepicker__font-size: 0.8rem !default;
$datepicker__font-family: 'Inter', sans-serif !default;
$datepicker__item-size: 1.7rem !default;
$datepicker__margin: 0.4rem !default;
$datepicker__navigation-button-size: 32px !default;
$datepicker__triangle-size: 8px !default;
@import "~react-datepicker/src/stylesheets/datepicker";

html, body {
  height: 100%;
  font-family: 'Inter', sans-serif;
}

#root {
  height: 100%;
  display: flex;
  flex-direction: column;
}

#page-content {
  flex: 1 0 auto;
}

.footer {

}

.date-range-container {
  background-color: #282F66 !important;
}

.date-range-wrapper {
  padding: 20px;
  display: flex;
  align-items: center;
  justify-content: center;

  .date-range-picker-wrapper {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
  }

  .item-wrapper {
    background: rgba(#fff, 0.4);
    color: #fff;
    font-weight: 500;
    padding: 10px 20px;
    border-radius: 5px;
    width: 200px;
    height: 44px;
    transition: all 0.25s ease-in-out;
    box-shadow: 0px 0px 20px 3px rgba(0, 0, 0, 0.35);

    overflow: hidden;
    text-overflow: ellipsis;
    word-wrap: break-word;
    white-space: nowrap;

    &.from {
      span.item-text {
        margin-left: 20px;
      }
    }

    &.number-of-people {
      width: 200px;

      span.item-text {
        margin-left: 20px;
      }
    }

    &:hover {
      cursor: pointer;
      background-color: #cbe5ff;
      color: #222222;
    }

    .item-icon {
      position: relative;

      .feather {
        position: absolute;
        left: -10px;
      }

      .font-awesome {
        position: absolute;
        left: -10px;
        width: 24px;
        height: 24px;;
      }
    }
  }

  .connector-icon {
    color: #fff;
  }
}

@media screen and (max-width: 992px) {
  .date-range-wrapper {
    padding: 10px;
    display: flex;
    flex-direction: column;

    .date-range-picker-wrapper {
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;
      width: 100%;
    }

    .item-wrapper {
      width: 100%;
      max-width: unset;

      .item-text {
        font-size: 0.85rem;
      }
    }

    .number-of-people {
      margin-top: 10px;
      margin-left: 0 !important;
      width: 100% !important;
      max-width: unset !important;
    }
  }
}

.picker-box-container {
  width: max-content;
  background: #fff;
  margin: 0 auto;
  padding: 20px;
  border-radius: 5px;
  box-shadow: 0px 0px 20px 3px rgba(0, 0, 0, 0.35);
  margin-top: -10px;
}

@media screen and (max-width: 992px) {
  .picker-box-container {
    width: fit-content;

    .react-datepicker {
      max-width: 320px;
    }
  }
}

@media screen and (max-width: 576px) {
  .picker-box-container {
    padding: 10px;
  }
}

.datepicker-group {
  .form-control {
    background: transparent;
  }

  .input-group-prepend {
    span {
      background: transparent;
    }
  }
}

.react-datepicker {
  border-color: rgba(#000, 0.1);
  font-family: unset;
  font-size: 1rem;
  padding: 20px;
  padding-top: 5px;
  min-height: 290px;

  .react-datepicker__day {
    width: 2rem;
    line-height: 2rem;
  }

  .react-datepicker__day--outside-month {
    color: rgba(#000, 0.4);
  }

  .react-datepicker__day-names {
    .react-datepicker__day-name {
      width: 2rem;
      line-height: 2rem;
    }
  }

  .react-datepicker__day--today {

    &:not(.react-datepicker__day--selected):not(.react-datepicker__day--in-range):not(.react-datepicker__day--in-selecting-range) {
      background-color: rgba(#000, 0.1);
      border-radius: 50%;
    }
  }

  .react-datepicker__navigation--previous {
    top: 15px;
    left: 10px;
  }

  .react-datepicker__navigation--next {
    top: 15px;
    right: 10px;
  }
}

.react-datepicker__header {
  background-color: transparent;
  border-color: rgba(#000, 0.1);

  .react-datepicker__current-month {
    font-size: 1.15rem;
    margin-bottom: 10px;
  }
}

#lang-nav-dropdown::after {
  display: none;
}

.dropdown-item {
  text-align: center;
  font-weight: 500;
  padding: 0.5rem 1rem;

  &:hover {
    background-color: transparent;
    color: #cbe5ff;
  }
}

.needed-apartments-container {
  .increment, .decrement, .remove-apartment {
    padding: 5px;

    .feather {
      color: #282F66;
      transition: all 0.25s ease-in-out;
    }

    &:hover {
      cursor: pointer;

      .feather {
        color: #cbe5ff;
      }
    }
  }

  .card-header {
    background-color: #282F66;
    color: #ffffff;
    padding: 5px 15px;
    min-height: 50px;

    .remove-apartment {
      .feather {
        color: #ffffff;
      }

      &:hover {
        .feather {
          color: #cbe5ff;
        }
      }
    }
  }

  .card-body {
    padding: 5px 15px;
  }
}

.btn-main {
  color: #fff;
  background-color: #282F66;
  border-color: #282F66;

  &:active, &.active, &:focus {
    color: #fff;
    background-color: #282F66;
    border-color: #282F66;
  }

  &:hover {
    color: #fff;
    background-color: #cbe5ff;
    border-color: #cbe5ff;
  }
}

.apartment-card {
  .recommended-badge-wrapper {
    position: absolute;
    z-index: 2;
    margin: 0;
    padding: 5px;
    background-color: #ffc422;
    border-radius: 0;
    //width: 40px;
    //height: 40px;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #ffffff;

    .feather {
      color: #eaeaea;
    }

    &.to-right {
      right: 0;
    }
  }

  .content-wrapper {
    //background-color: #f3f3f3;
    border-radius: 5px;
    padding: 10px;

    .section-title {
      font-weight: 500;
    }

    hr {
      margin-bottom: 10px;
      margin-top: 5px;
    }

    p {
      margin-bottom: 5px;
    }

    .configuration-info-span {
      font-size: 0.85rem;
    }
  }

  .price-wrapper {
    .price {
      font-size: 3rem;
      font-weight: 500;
    }
  }

  .card-title {
    font-weight: bold;
    font-size: 1.25rem;
  }

  .card-body {
    padding: 10px;
    padding-left: 15px;
  }
}

.feather-spin {
  animation-name: spin;
  animation-duration: 5000ms;
  animation-iteration-count: infinite;
  animation-timing-function: linear;
}

@keyframes spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.footer {
  padding-top: 40px;
  padding-bottom: 10px;
}

.rsis-container {
  div {
    background-position: center !important;
    background-repeat: no-repeat !important;
  }
}

.details-modal {
  .modal-content {
    overflow: hidden;
  }

  .details-modal-body {
    padding: 0;
  }

  .details-content {
    padding: 15px;

    .price {
      font-size: 2.5rem;
    }
  }

  .apartment-name-card {
    margin-top: -30px;

    .apartment-name {
      font-weight: bold;
      font-size: 1.25rem;
    }
  }

  .shadow {
    box-shadow: 0px 0.25rem 1rem rgba(0, 0, 0, 0.15) !important;
  }
}

@media screen and (max-width: 992px) {
  .details-modal {
    padding-left: 0 !important;
  }
}

.apartment-details-image-wrapper {
  height: 500px;
}

@media screen and (max-width: 992px) {
  .apartment-card-image {
    height: 200px;
  }

  .apartment-details-image-wrapper {
    height: 250px;
  }

  .date-range-wrapper {
    padding: 10px;
  }
}

.apartment-list {
  .price-wrapper {
    display: flex;
    align-items: center;
    justify-content: end;
  }

  @media screen and (max-width: 992px) {
    .price-wrapper {
      display: block;
      margin-top: 20px;
      padding-top: 20px;
      border-top: 1px solid rgba(#000000, 0.1);
    }

    .buttons-wrapper {
      display: flex;
      align-items: flex-end;
      justify-content: space-between;
      flex-direction: column;
    }
  }
}

@media screen and (max-width: 992px) {
  .modal-xl {
    max-width: calc(100% - 20px);
  }

  .modal-lg {
    max-width: calc(100% - 20px);
  }
}
.feather-xl {
  width: 100px;
  height: 100px;
}

.clickable {
  &:hover {
    cursor: pointer;
  }
}

.btn-main-light {
  color: #222;
  background-color: #cbf0ff;
  border-color: #cbf0ff;

  &:active, &.active, &:focus {
    color: #222;
    background-color: #cbf0ff;
    border-color: #cbf0ff;
  }

  &:hover {
    color: #222;
    background-color: #cbe5ff;
    border-color: #cbe5ff;
  }
}

.details-2 {
  .apartment-name {
    margin-bottom: 20px;
    font-size: 4rem;
  }

  .title-image {
    width: 100%;
    min-height: 80vh;
    background-color: rgba(#000000, 0.25);
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    background-blend-mode: multiply;

    color: #ffffff;

    position: relative;

    .title-content-wrapper {
      position: absolute;
      top: 0;
      left: 0;
      padding: 40px;
    }

    .book-wrapper {
      padding: 40px;
      border: 1px solid #ffffff;
      border-radius: 5px;
    }

    .description {
      font-size: 14px;
    }
  }

  .p-price {
    font-size: 2rem;

    .price {
      font-size: 3rem;
    }
  }

  .info-table-wrapper {
    padding: 40px;
    font-size: 15px;

    .info-table-item {
      padding: 10px;
      border: 1px solid #222;
      margin-top: -1px;
      margin-left: -1px;
    }
  }

  .eq-info-table-wrapper {
    padding: 40px;
    font-size: 15px;

    .eq-info-table-item {
      padding: 10px 0;
      border-bottom: 1px solid #222;

      .font-awesome {
        width: 24px;
        height: 24px;
        margin-bottom: -4px;
      }
    }
  }

  .slider-wrapper {
    width: 100%;
    padding-top: 56.25%;
    position: relative;

    .slider-wrapper-inner {
      position: absolute;
      top: 0;
      left: 0;
      bottom: 0;
      right: 0;
    }
  }

  .book-btn {
    padding: 20px;
  }

  .book-bottom-wrapper {
    margin: 40px;
    background-position: left;
    background-repeat: no-repeat;
    background-size: cover;
    color: #ffffff;
    position: relative;

    .lead {
      font-size: 2rem;
    }

    .lead-2 {
      font-size: 1.25rem;
    }

    .content {
      padding: 60px;
      background-color: rgba(#282F66, 0.95);
    }
  }
}

@media screen and (max-width: 992px) {
  .details-2 {
    .title-image {
      min-height: 100vh;

      .title-content-wrapper {
        padding: 10px;
        position: unset;
      }
    }

    .eq-info-table-wrapper {
      padding: 10px;

      .eq-info-table-item-wrapper:last-child {
        .eq-info-table-item {
          border-bottom: none;
        }
      }
    }

    .book-bottom-wrapper {
      margin: 10px;
    }
  }
}